/**
 * @file
 * RTL companion for the styles.scss file.
 */

/* Import Sass mixins, variables, Compass modules, etc. */
@import "init";

/* HTML element (SMACSS base) rules */
@import "normalize-rtl";

/* Layout rules */
@import "layouts/responsive-rtl";

/* Component (SMACSS module) rules */
@import "components/misc-rtl";

/* SMACSS theme rules */
/* @import "theme-A-rtl"; */
/* @import "theme-B-rtl"; */
