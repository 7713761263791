//.content,
//.content p,
//body,
//td,
//input,
//textarea,
//fieldset {
//  font-size:1.6em;
//  line-height:1.75em;
//}

#comments{ 
	h2.comment-form {
		background-position: 65% 60%;
	}
}
//#page {
//  p,
//  .content,
//  .content p,
//  td,
//  input,
//  textarea,
//  fieldset,
//  {
//    font-size: 1.6em;
//    line-height: 1.3em;
//  }
//  h1 {
//    font-size: 1.9em;
//  }
//  h2{
//    font-size: 1.8em;
//  }
//  h3{
//    font-size: 1.7em;
//  }
//}