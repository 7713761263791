/**
 * @file
 * RTL companion for the layout-responsive.css file.
 */

// First we tell Zen grids to reverse the direction of all floats.
$zen-reverse-all-floats: true;

// Then we import the LTR layout and the directions are automatically reversed.
@import "responsive";
