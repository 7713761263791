//@font-face {
//font-family: 'Eileen';
//src: url("../fonts/eileencaps-black.eot");
//src: local("Ä¿ ´¿¨Ä"),
//	url("../fonts/eileencaps-black.ttf") format("truetype"),
//	url("../fonts/eileencaps-black.woff") format("woff");
//font-style: normal;
//font-weight: normal;
// IM+Fell+French+Canon+SC, Permanent Marker, Marcellus SC, Mate SC, Alegreya SC
//}

@import url('https://fonts.googleapis.com/css?family=Alegreya+SC:400|Alegreya:400');

html {
  font-size: 16px;
  line-height: 1.65;
  text-rendering: optimizeLegibility;
  font-variant-ligatures: discretionary-ligatures;
  font-feature-settings: "kern";
  font-kerning: normal;
}

// @import url('https://fonts.googleapis.com/css?family=IM+Fell+DW+Pica');
//@import url('https://fonts.googleapis.com/css?family=IM+Fell+Double+Pica+SC');

//@font-face {
//  font-family: 'IMFellDoublePicaFF';
//  src: url("../fonts/imfedpsc28pbold-webfont.eot");
//  src: local("Ä¿ ´¿¨Ä"),
//  url("../fonts/imfedpsc28pbold-webfont.ttf") format("truetype"),
//  url("../fonts/imfedpsc28pbold-webfont.woff") format("woff"),
//  url("../fonts/imfedpsc28pbold-webfont.svg#im_fell_double_pica_scregular") format("svg");
//  font-style: normal;
//  font-weight: normal;
//}

body {
  .tech-logo,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .slogan-welcome,
  .dropcap-titling {
    font-family: $font-stack-sc;
    font-weight: normal;
    line-height: 1.65;
    hyphens: auto;
  }
}

body {
  .block-book a,
  .block-class-block-toc a,
  .share-label,
  .dot-dot-dot,
  caption,
  .links,
  .username,
  #login-message,
  .field-name-field-weight .field-item,
  .view-footer,
  th,
  legend,
  select,
  option,
  #navigation,
  #site-name,
  .fieldset-legend,
  .readmore-teaser a,
  .readmore-teaser,
  .submitted-author a,
  .submitted-author a,
  #author-name {
    hyphens: auto;
    font-family: $font-stack-header;
    font-weight: normal;
    line-height: 1.65;

    html.chrome & {
      -webkit-text-stroke: 0.25px;
    }
  }
}


#site-slogan,
#block-glass-block-reader-apply,
.quote,
li,
p,
td,
input,
textarea,
fieldset {
  font-family: $font-stack-body;
  font-weight: normal;
  font-size: 1.4rem;
  color: $black-brown;

  @include mobile-up {
    font-size: 1.35rem;
  }

  @include tablet-up {
    font-size: 1.38rem;
  }
}

.dropcap-paragraph {
  hyphens: auto;
}

.p-dropcap-b-zoomorphic,
.p-dropcap-c-reader,
.p-dropcap-d-hellmouth,
.p-dropcap-e,
.p-dropcap-i-law,
.p-dropcap-n-monster,
.p-dropcap-n-reader,
.dropcapimg-s-dragon,
.p-dropcap-s-jonah,
.p-dropcap-t-shark,
.p-dropcap-u,
.p-dropcap-w-horseman {
  .dropcap-wrapper {
    display: block;
    width: 100%;
    float: left;
    @include  tiny-up {
      width: unset;
      display: inline;
      float: none;
    }
  }
}

// Turn of dropcap for mobile.
//.dropcap-paragraph .dropcap-text {
//  display: inline !important;
//  @include mobile-up {
//    display: none !important;
//  }
//}

//.dropcap-wrapper {
//  display: none;
//  @include mobile-up {
//    display: inline;
//  }
//}

.field-name-body {
  @include mobile-up {
    text-align: justify;
    hyphens: auto;
  }
}

h2 {
  margin-bottom: .5em;
  margin-top: .5em;
  font-size: 1.8rem;
}

h1 {
  font-size: 2.8rem;
}

legend, .fieldset-legend {
  font-size: 1.3rem;
}

body h1.page__title {
  font-size: 2.4rem;
  hyphens: none;
}

.main__has-img .page__title {
  text-align: center;
}

.views-field-title {
  font-size: 1.3rem;
}

h3 {
  font-size: 1.6rem;
}

a {
  text-decoration: none;
}

a:hover {
  transition: background-color 0.5s ease;
  color: $dark-brown;
}

ul {
  margin-left: 2em;
  margin-top: .5em;
}

.field-name-body {
  ol, ul {
    margin-top: .5rem;
    margin-left: 4rem;
  }

  ul li {
    list-style-image: url("/sites/all/themes/glassdimly_zen/images/finger_pointing_right_tiny.png");
    margin-bottom: .3rem;
  }

  ol li {
    list-style-image: none;
  }
}

.node-not-teaser .dropcap-text {
  font-family: $font-stack-dropcap;
  display: block;
  float: left;
  font-size: 5rem;
  height: 0.58em;
  padding-top: 0.33em;
  font-style: normal;
  font-weight: normal;
  color: $black-brown;
}


#block-glass-block-toc-mobile legend a {
  font-size: 1.5rem;
}

.block-class-block-toc a, .block-class-block-toc span {
  font-size: 1.3rem;
}

.block-book {
  a {
    font-size: 1rem;
    line-height: 1.65;
  }
  a.fieldset-title {
    font-size: 1.75rem;
  }
}

#site-name {
  a {
    color: $black-brown;
  }
  font-size: 1.94rem;
}

.share-label {
  float: left;
  margin-left: 14px;
  margin-right: 5px;
  clear: both;
}

.dot-dot-dot {
  font-size: 1.25rem;
  color: $chocolate;
  margin-right: 5px;
  text-decoration: none;
}

.block .title {
  color: $dark-brown;
}

blockquote, .quote {
  font-style: italic;
  color: black;
}

div .item-list ul li,
div ul li,
li,
ul li {
  list-style-image: url('/sites/all/themes/glassdimly_zen/images/bullet.png')
}

div #skip-link, div #page {
  font-size: 1rem;
}

.block-class-block-toc li.active a {
  color: $chocolate;
}

a {
  text-decoration: none;
  color: $chocolate;
}

h1 {
  font-size: 1.75em;
  @include mobile-up {
    font-size: 2em;
  }
}

h1 a,
.block-class-block-toc a,
h2 a {
  color: $dark-brown;
}

.field-label-inline div,
.field-label-inline span,
.field-label-inline-first {
  text-transform: lowercase;
}

input, textarea {
  color: $dark-brown;
  font-weight: bold;
  font-size: 1em;
}

input[type="submit"] {
  text-indent: 0;
  color: $dark-brown;
  font-family: $font-stack-body;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  height: 35px;
}

#dsq-content #dsq-global-toolbar .dsq-global-toolbar-right li,
#dsq-content #dsq-footer li,
#block-disqus-disqus_comments li {
  list-style: none;
}

.ellipse {
  overflow: hidden;
  line-height: 1px;
  white-space: nowrap;
  font-size: 20px;
}

#navigation {
  font-size: 1.6rem;
}

#footer #block-block-3 {
  font-size: 1rem;
}

html .quote p, html .quote, html .quote div, html .quote span, html .quote blockquote {
  color: black;
}

.field-type-taxonomy-term-reference {
  a {
    font-style: italic;
  }
  .node-meta-left & {
    text-align: right;
  }
}


.field-name-field-submitted {
  font-size: 1.25rem;
  text-align: right;
}

html:not(.webkit) body:not(.front) {
  .field-name-body .field-items .field-item > p:nth-child(1):first-line,
  .views-row .field-item p:nth-child(1):first-line {
    text-transform: uppercase;
    font-size: .725em;
    font-family: $font-stack-header;
  }
}

.submitted-date {
  font-style: italic;
}

.field-label {
  font-style: italic;
  font-weight: bold;
}

.page-num-wrapper {
  font-style: italic;
}

#main .field-name-field-weight {
  div, span {
    font-weight: normal;
    font-style: normal;
    font-size: 1.05rem;
    text-transform: capitalize;
  }
}

body.node-type-book {
  .dropcap-titling {
    text-transform: none;
    font-size: 1.27rem;
    display: block;
  }
  .book-navigation a {
    font-weight: bold;
    font-style: italic;
  }
}

html time {
  font-style: italic;
}

.username {
  font-size: 1.2rem;
}

article {
  p {
    position: relative;
    z-index: 3;
  }
  p.p-dropcap-auto-first {
    z-index: 1;
  }
  img.dropcap {
    z-index: 0;
  }
}

.tech-looking {
  font-style: italic;
  a {
    font-weight: bold;
  }
}

.tech-logo {
  font-weight: bold;
  font-style: normal;
  font-size: 3rem;
  .tech-tech {
    color: #369336;
  }
  .tech-blog {
    color: $chocolate;
  }
  .tech-brace {
    color: #C47D5F;
  }
}

#toc-header {

  h2.block__title {
    margin-top: 0;
    font-size: 1.6rem;

    @include tablet-up {
      margin-top: .7rem;
    }
  }
  .fieldset-legend {
    font-size: 32px;

    @include mobile-up {
      font-size: 41px;
    }
  }
  .block-book a {
    line-height: 1.65px;
    font-size: 1.83rem;
  }
}

.wf-loading {
  display: none;
}

.view-tweets td {
  font-size: .95rem;
}

#edit-apply-for-role .fieldset-legend a {
  font-size: 1.4em;
}
.toboggan-unified {
  a#login-link {
    font-size: .7em;
  }
  a#register-link {
    font-size: .7em;
  }
}

.dropcap-img-wrapper img {
  max-width: unset;
}

.toc-title, .page-num {
  font-size: 1.275em;
}

.view-hivemind h2 {
  margin-bottom: 0;
  margin-top: 2rem;
}

.view-hivemind .view-content .field-name-body p {
  margin: 0.2em 0;
}
