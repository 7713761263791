#content, #main, #header, #page, body {
  margin: 0;
  width: 100%;
  min-width: 0;
}

html {
  overflow-x: hidden;
}

body {
  width: 100%;
  overflow-x: hidden;
  background-color: $background;
}

#page {
  margin: auto;
  width: 100%;
}

#header {
  width: 100%;
  max-width: 1580px;
  margin-right: auto;
  margin-left: auto;

  @include xlarge-desktop-up {
    width: 95%;
  }
}

#main {
  clear: both;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: $max-content-width;
}

footer {
  background-size: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  clear: both;
}

#content, aside.sidebars {
}

.sidebar-second #content {
  margin-left: 0;
  width: calc(#{$front-left-total-width});
  float: left;

  @include xlarge-desktop-down {
    padding-left: 2%;
    width: calc(#{$front-left-total-width} - 2%) ;
  }
}

aside.sidebars {
  padding-left: $front-right-padding-left;
  width: calc(#{$front-right-total-width} - #{$front-right-padding-left});
  float: left;
  margin-top: 3.5rem;

  @include xlarge-desktop-down {
    padding-right: 2%;
    width: calc(#{$front-right-total-width} - #{$front-right-padding-left} - 2%) ;

  }
}

#content {
  padding-bottom: 75px;

  .block {
    float: left;
    width: 100%;
  }
}

html.webkit {
  #toc-header ul.child {
    top: 0px;
  }

  hr.border {
    top: 12px !important;
  }
}

.view-Similar-Entries {
  .views-field-field-post-image-1 {
    position: absolute;
    box-shadow: #000 0em 0em 0em;
    //left: 50%;
    //transform: translate(-50%, 0);
  }

  //.views-field-field-post-image {
  //  display: inline-block;
  //}

  .views-row {
    width: 90%;
    margin-bottom: 20px;
    position: relative;
    //text-align: center;

    @include mobile-up {
      float: left;
      margin-right: 20px;
      width: 40%;
      min-width:190px;

      &.views-row-odd {
      clear: both;
      }
    }
  }
}

#dimmer {
  z-index: 10;
  width: 100%;
  height: 700%;
  display: none;
  background-color: $tan;
  opacity: .63;
  position: absolute;
}

img.hover-over {
  position: absolute;
  box-shadow: #000 0em 0em 0em;
}

#navigation {
  text-transform: capitalize;
  margin-bottom: 10px;
  height: 30px;
}

#header {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  @include clearfix-chill;

  #header-left {
    float:left;
    width: 100%;

    @include tablet-up {
      width:50%
    }
  }

  #header-right {
    float:left;
    width: 100%;
    margin-top: 2rem;

    @include mobile-up {
    }

    @include tablet-up {
      margin-top: 10%;
      width: 50%;
    }
  }

  .section {
    height: 172px;
    margin-left: 69px;
    position: relative;
    top: 71px;
    z-index: 100;
  }

  #logo {
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;

    .logo-picture {
      display: none;
      margin-top: 1.5rem;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      width: 65%;

      @include mobile-up {
        display: block;
        width: 70%;
      }

      @include tablet-up {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        float: left;
      }

      @include desktop-up {
        margin-top: 0;
      }
    }
  }

  #logo:hover img {
    opacity: 0.7;
  }

  h1.site-name {
    font-size: 1.75rem;
    line-height: .9;
    float: left;
    margin-left: 1rem;
    padding-right: 2rem;
    color: $logo-color;
    margin-bottom: 1rem;

    a {
      color: $logo-color;
    }

    @include mobile-up {
      font-size: 2.5rem;
    }

    @include tablet-up {
      width: 56vw;
      padding-right: 0;
      position: absolute;
      font-size: 2.9vw;
      text-align: right;
      top: 4rem;
      right: 1rem;
    }

    @include desktop-up {
      font-size: 2.9vw;
    }

    @include xlarge-desktop-up {
      width: 75vw;
      font-size: 2.1vw;
    }
  }

  .slogan-bio-wrapper {
    float: left;
    @include clearfix-chill;
    width: 100%;

    @include tablet-up {
      margin-top: 40px;
    }

    @include desktop-up {
      margin-top: 20px;
    }

    @include xlarge-desktop-up {
      margin-top: 0;
    }
  }

  #site-slogan {
    line-height: 1.25;
    //display: inline;
    font-size: 1.1rem;
    float: left;
    margin-left: 1rem;
    max-width: calc(100% - 110px - 2rem);
    margin-top: 0;
    margin-bottom: 0;


    @include tablet-up {
      font-size: 2vw;
      float: right;
      text-align: right;
      margin-right: 1rem;
      margin-left: 0;
    }

    @include desktop-up {
      font-size: 1.2rem;
    }

    @include xlarge-desktop-up {
      font-size: 1.5rem;
    }

    .slogan-text {
      display: block;
      margin-bottom: .3em;
    }


    .slogan-welcome {
      font-size: larger;
      line-height: 1;

      @include tablet-up {
        line-height: 1.5;
      }
    }
  }

  .bio-pic {
    margin-left: 1rem;
    width: 100px;
    float: left;
    vertical-align: middle;

    @include tablet-up {
      margin-right: 1rem;
      margin-left: 0;
      position: static;
      float: right;
      width: 25%;
    }
  }

  .readmore-teaser {
    @include tablet-up {
      margin-left: 30px;
      display: block;
      text-align: right;
    }
  }
}

html fieldset {
  border-color: $tan;
}

/**input elements */
#content input {
  max-width: 100%;
}

input[type="radio"], input[type="checkbox"] {
  height: 1.1em;
}

article.node {
  .node-meta {
    width: auto;
    min-height: 70px;
    margin-bottom: 30px;

    .node-meta-left {
      float: left;
      width: 100%;

      .field-type-taxonomy-term-reference, .field-name-field-submitted {
        clear: both;
      }

      .field-type-taxonomy-term-reference {
        .field-label {
          display: inline;
          padding-right: 0px;
        }
      }

      .field-name-field-weight {
        .field-label {
          padding: 0;
        }
      }
    }

    .node-meta-right {
      float: right;
      text-align: center;
      width: 42%;

      .rdbWrapper {
        width: 105px;
        margin-right: auto;
        margin-left: auto;
      }
    }

    .field-name-taxonomy-vocabulary-1 {
      clear: both;
    }
  }
}

body.node-type-book {
  article.node-342 {
    #book-navigation-342 {
      display: none;
    }
  }
}

.page-node-342 {
  .block-book {
    .intro span {
      color: $black-brown;
    }
  }
}

body.section-404 {
  #block-disqus-disqus-comments,
  #sharing-caring {
    display: none;
  }
}

.node-type-page.body-dropcap-y #content {
  margin-top: 150px;
}

.body-dropcap-h,
.body-dropcap-y {
  .tech-blog-header {
    margin-left: 50px;
  }
}

.geshifilter {
  position: relative;
  z-index: 2;
}

#block-block-3 {
  width: 100%;
  text-align: center;
}

.book-navigation {
  .page-up {
    width: 7%;
  }
  .page-previous, .page-next {
    width: 41%
  }
}

input {
  height: 35px;
}

input, textarea {
  background-color: $dark-creme;
  border-top: none;
  border-left: none;
  box-shadow: -1px 1px 1px 0 $tan;
  border-bottom: none;
  border-radius: 4px;
  border-right: none;
}

input[type="submit"] {
  -moz-box-shadow: inset 0px 1px 0px 0px $dark-brown;
  -webkit-box-shadow: inset 0px 1px 0px 0px $dark-brown;
  box-shadow: -1px 1px 1px 0 $tan;
  background-color: $tan;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, $dark-creme), color-stop(1, $creme));
  background: -moz-linear-gradient(center top, $dark-creme 5%, $creme 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$dark-creme', endColorstr='$creme');
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid $creme;
  height: 35px;
  padding-top: 3px;

  :hover {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, $creme), color-stop(1, $dark-creme));
    background: -moz-linear-gradient(center top, $creme 5%, $dark-creme 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$creme', endColorstr='$dark-creme');
    background-color: $tan;
  }

  :active {
    position: relative;
    top: 1px;
  }
}

/** twitter block themin, but general for tables */
div tr.even, div tr.odd {
  border-bottom: none;
  background-color: transparent;
}

#block-glass-block-reader-apply-mobile {
  display: none;
}
/** email signup block */
#block-glass-block-notify-mobile {
  margin-top: 2rem;

  #edit-name {

  }

  input {

  }
}

#block-glass-block-notify,
#block-glass-block-notify-mobile {

  .block__title {
    font-size: 1.8rem;
  }

  @include tablet-down {
    font-size: 80%;
  }


  form .form-text {
    width: 150px;
    float: left;
    position: relative;
    margin: 0px;
    border-right: none;
    padding-bottom: 0;
    padding-left: 10px;
    padding-top: 10px;
    font-size: .8em;
    color: $tan;
     &:focus, &:active {
       color: $black-brown;
     }

    @include tiny-up {
      font-size: 1em;
      width: 170px;
    }
  }

  form .form-submit {
    float: left;
    border-bottom: none;
    border-right: none;
    height: 36px;
    left: -2px;
    position: relative;
    top: 0px;
    width: 100px;
    max-width: 35%;


    .sidebars & {
      max-width: 80px;
    }

    @include mobile-up {
      max-width: none;
    }

    @include tablet-down {
      position: static;
    }
  }

  .login {
    margin-left: 5px;
    display: inline;
    white-space: nowrap;
  }

  .form-item {
    margin: 0;
    width: 250px;
  }
}

#block-glass-block-manuscript-files {
  ul {
    margin: 0;
  }

  p {
    margin: .5em;
    font-weight: bold;
    font-style: italic;
  }
}

#block-glass-block-toc-mobile {
  display: none;
  margin-bottom: 0px;
}


html .block-book {

  ul.menu {
    padding: 0;
    margin-top: .3em;
  }

  li.menu__item {
    list-style-image: none;
    list-style-type: none;
  }

  position: relative;
  left: -30px;
  margin-bottom: 0;

  h2.block__title {
    display: none;
  }
}

#novel-progress {
  background: url("/sites/all/themes/glassdimly_zen/images/progress-background.png");
  height: 20px;
  width: 350px;
  border: none;
  color: $dark-creme;

  .ui-progressbar-value {
    color: $chocolate;
    background: url("/sites/all/themes/glassdimly_zen/images/progress-value.png");
    border: none;
  }
}

/**menu block*/

body.toc-header-active #toc-header{
  width: 100%;
}

#toc-header ul.parent {
  padding: 0;
}

#toc-header .fieldset-legend {
  @include mobile-down {
    font-size: 35px !important;
  }

}
.block-class-block-toc, .block-book {
  margin-bottom: $glass-page-gutter;
  min-height: 50px;
  max-width: 90%;

  ul.links {
    margin-bottom: 0px;
    padding: 0;
    margin: 0;
  }

  ul.links li {
    display: block;
  }

  .ellipse {
    margin-left: 13px;
    margin-right: 13px;
    height: 8px;
    overflow: hidden;
    display: block;
  }

  .page-num {
    float: right;
    position: relative;
    top: .2em;
  }
}

body.html {
  #toc-header {
    margin-top: 0px;
    width: 65%;
    min-width: 300px;
    max-width: 500px;
    z-index: 11;
    position: absolute;

    #slogan {
      margin: .5em 0;
    }

    .fieldset-legend {
      padding-right: 20px;
      cursor: pointer;
      display: block;
      text-align: left;

      img {
        position: relative;
        left: 4px;
      }
    }

    h2.block__title {
      display: block;
    }

    .form-wrapper > ul.parent {
      margin: 0;

      > li.parent {
      }
    }

    ul li.parent, ul li ul li.first {
      list-style-type: none;
      list-style-image: none;
    }

    ul#social-options {
      margin-top: .5em;
    }

    ul.child {
      padding-right: 25px;
      padding-top: 20px;
      position: relative;
      top: 0px;

      li.social {
        margin-left: 43px;
        list-style-image: url('/sites/all/themes/glassdimly_zen/images/finger_pointing_right_tiny.png')
      }
    }

    ul.parent {
      text-align: center;

      ul.child {
        text-align: left;
      }
    }
  }

  &.toc-header-active #toc-header {
    transform: unset;
    left: 0;
    width: 95%;
  }
}

body.toc-header-active {
  #dimmer {
    display: block;
  }
}

#toc-header ul.parent {
  background-color: transparent;
  border: none;
}

.toc-header-active #toc-header ul.parent {
  border: 1px solid $chocolate !important;
  padding-bottom: 1rem;
  display: block;
  background-color: $creme;
}

//  fallback for javascript nav
body.toc-header-active-fallback #toc-header nav ul li ul {
  display: none;
}

nav hr.border {
  display: none;
}

body.toc-header-active-fallback #toc-header nav ul:hover li ul {
  display: block;
}

body.toc-header-active-fallback #toc-header nav ul:hover.parent ul.child,
body.toc-header-active-fallback #toc-header nav ul:hover.parent .fieldset-legend,
body.toc-header-active #toc-header nav ul.parent ul.child,
body.toc-header-active #toc-header nav .fieldset-legend {
  background-color: $creme;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 0;
  transition: 0.15s padding ease-in;

  @include tablet-up {
    padding-top: 1rem;
  }
}

body.toc-header-active-fallback #toc-header nav ul:hover ul.child,
body.toc-header-active #toc-header nav ul ul.child {
  border-top: none;
}

body.toc-header-active-fallback #toc-header nav ul:hover .fieldset-legend,
body.toc-header-active #toc-header nav ul .fieldset-legend {
  border-bottom: none;
  z-index: 14;
}

body.toc-header-active #toc-header .nav-toggle-open {

}

.nav-toggle-open {
  display: none;
}

body.toc-header-active #toc-header {
  .nav-toggle-open {
    display: inline-block;
    font-size: 25px;
  }
  .nav-toggle-closed {
    display: none;
  }
}

body.toc-header-active-fallback #toc-header nav ul:hover hr.border,
body.toc-header-active #toc-header nav ul hr.border {
  background: $chocolate;
  border: none;
  position: absolute;
  width: 532px;
  height: 1px;
  z-index: -1;
  top: 12px;
  opacity: .99;
  display: block;
}

#toc-header {
  max-width: $max-content-width;
  margin-right: auto;
  margin-left: auto;
  left: 1rem;

  .page-num {
    display: none;

    @include mobile-up {
      display: block;
    }
  }
}

#links__system_main_menu {
  margin-top: 0;
  clear: both;
  display: block;

  @include tablet-up {
    margin-top: 1rem;
  }
}

aside.sidebars {
  .block {
    clear: both;
    float: left;
  }

  input {
    max-width: 75%;
  }

  /**search block*/
  .block-search {
    .form-submit {
      position: relative;
      height: 36px;
      left: -7px;
      width: 100px;
      top: -4px;
    }

    .form-text {
      border-right: none;
      width: 190px;
      padding-bottom: 0;
      padding-left: 10px;
      padding-top: 10px;
    }
  }
  #block-views-tweets-block {
    .view-footer {
      text-align: center;
    }

    .views-field-text {
      font-size: 1.2rem;
    }

    .views-field-profile-image-url {
      width: 60px;
      height: 60px;
      margin-right: 5px;
      img {

      }
    }

    tr td {
      padding-bottom: 10px;
    }
  }

  /** quotes block */
  #block-views-quotes-block-1 {

    ul {
      list-style-type: none;
      margin-left: 15px;
    }

    li {
      list-style-image: none;
    }

    .readmore-teaser {
      display: block;
      margin-left: 1rem;
    }

    .view-footer {
      margin-left: 60px;
    }
  }
  /** war of the worlds walker block **/
  #block-block-12 {
    height: 240px;
    margin: 0;
    margin-top: 30px;

    img {
      position: relative;
      bottom: 73px;
      left: 82px;
    }
  }

  /** block 13 is auryn block*/
  #block-block-13 {
    height: 130px;
    margin: 0;
    position: relative;
    text-align: center;
    top: 35px;
    width: 400px;
  }

  /** social justice links, above auryn block */
  #block-views-links-block-2 {
    z-index: 1;
  }

  /** syndicate block */
  #block-node-syndicate {
    text-align: center;
  }

  /** auryn block */
  .block #auryn {
    position: relative;
    top: -60px;
  }

  #block-views-links-block_2 {
    margin-top: 20px;
  }

  .view-links p {
    margin: 0em;
  }

  .view-content ul li {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

html.chrome aside.sidebars {
  .block-search .form-submit {
    top: -4px;
  }
}

div#views_infinite_scroll-ajax-loader {
  z-index: 10;
  height: 60px;
  width: 80px;
  margin-left: $glass-page-gutter;
}


body.front .quote,
body.front .views-field-field-clippings-value .field-item,
.quote,
.views-field-field-clippings-value .field-item,
blockquote {
  background: center/100% url("/sites/all/themes/glassdimly_zen/images/highlight-desktop.png");
  background-size: 100% 78px;
  padding-left: 2%;
  margin-left: -2%;
  padding-right: 2%;
  margin-right: -2%;
  width: 100%;
}

html article.node-quote.view-mode-full .quote {
  font-style: normal;
}

div blockquote.quote {
  margin-left: 25px;
  max-width: calc(100% - 40px)
}

.stbuttontext {
  position: relative;
  bottom: 5px;
}

#sharing-caring {
  display: block;
  margin-top: 1rem;

  .sharing {
    float: left;
    display: block;
    clear: both;
  }

  .share-label-fb {
    float: left;
  }


  #fb-share {
    position: relative;
    top: 2px;
    float: left;
    right: 7px;
    width: 310px;
  }

  #twitter-share {
    position: relative;
    top: 4px;
    float: left;
  }

  .st_sharethis {
    position: relative;
    top: -2px;
    right: 5px;
    float: left;
  }
}

/* fb block */
div .uiBoxLightblue {
  background-color: $dark-brown;
  border: 1px solid $light-blue-gray;
}

.fb-social-like-widget {
  margin-bottom: 1px;
  margin-left: 7px;
}

.front .breadcrumb {
  display: none;
}

.clear-float {
  clear: both;
  display: block;
  height: 1px;
}

.view-quotes .field-label {
  padding-right: 3px;
}

.view-content {
  .field-name-body {
    margin-left: 1rem;

    p {
      margin: 0.5em 0;
    }
  }

  .links {
    margin-left: 2rem;

    li {
      padding: 0;
    }
  }
}

.form-type-password-confirm {
  width: 390px;

  .form-type-password {
    width: 390px;

    label {
      float: left;
      clear: both;
    }

    .password-strength {
      float: left;
    }
  }
}

.fieldset-wrapper {
  max-width: 80vw; // Gah. No idea why 100% doesn't work here. For login pw form.
}

#user-register-form #edit-apply-for-role-message,
#apply-for-role-apply-form, .form-item-message textarea {
  height: 200px;
}

.page-user-register #page-title {
  display: none;
}

#edit-apply-for-role-message, #edit-captcha-response--2 {
  width: 80%;
}

#edit-notify-agree .fieldset-legend {
  font-size: 29px;
}

#login-links a {
  white-space: nowrap;
}

#edit-pass-pass1 {
  width: 100%;
}

html {
  .toboggan-unified #login-links a.lt-active,
  .toboggan-unified #login-links a.lt-active:hover {
    background-color: $tan;
    color: $dark-brown;
    border-color: $chocolate;
  }

  .toboggan-unified #login-links a {
    background-color: $dark-creme;
    color: $tan;
    border-color: $tan;

  }
}

.xdebug-var-dump {
  background: white;
}

div.krumo-root {
  width: 650px;
  z-index: 50000000;
}

.comments {
  p {
    margin: .5em 0;
  }



  .indented {
    margin-left: 20px;
    background-image: url('/sites/all/themes/glassdimly_zen/images/reply.png');
    background-repeat: no-repeat;
    background-position: 1% 0.6%;
    margin: 0;
    padding-left: 20px;
  }

  h2.comment-form {
    background-image: url('/sites/all/themes/glassdimly_zen/images/bullet_animated.gif');
    background-position: 50% 60%;
    background-repeat: no-repeat;
  }

  .comment-reply {
    background-image: url('/sites/all/themes/glassdimly_zen/images/reply.png');
    background-repeat: no-repeat;
    background-position: 1% 0.6%;
    margin: 0;
    padding-left: 20px;
  }
}


#comment-form {
  .form-item {
    margin: 0.5em 0;
  }

  .form-item-comment-body-und-0-value {
    margin: 0;
  }

  textarea {
    height: 300px;
  }

  .form-type-select .filter-list {
    display: none;
  }

  .filter-help, .form-type-select {
    display: none;
  }

  .filter-wrapper {
    padding: .3em 0;
  }

  #edit-actions {
    margin-top: .5em;
  }
}

.no-bottom-margin {
  margin-bottom: 0;
}

body.node-type-audio {

  #block-block-3,
  #sharing-caring {
    display: none;
  }

  div.jp-audio {
    color: $dark-brown;
  }

  div.jp-progress {
    background-color: $dark-creme;
  }

  .field-name-field-audio .field-label {
    display: none;
  }

  div.jp-interface {
    background-color: transparent;
  }

  div.jp-playlist ul {
    background-color: $dark-creme;
    padding: 0;
  }

  div.jp-playlist {
    border-color: $chocolate;
    font-style: italic;
  }

  div.jp-type-playlist div.jp-playlist li {
    list-style-image: none;
    list-style: none;
  }

  div.jp-playlist a {
    color: $chocolate !important;
    :hover {
      color: $dark-brown !important;
    }
  }

  div.jp-playlist a.jp-playlist-current {
    color: $dark-brown !important;
    :hover {
      color: $black-brown !important;
    }
  }

  div.jp-audio div.jp-type-playlist div.jp-duration {
    top: 59px;
    left: 9px;
    width: 122px;
  }

  div.jp-audio div.jp-type-playlist div.jp-current-time {
    top: 60px;
    left: 10px;
    width: 122px;
  }

  div.jp-audio div.jp-type-playlist div.jp-progress {
    top: 62px;
    left: 10px;
    width: 122px;
    height: 15px;
  }

  div.jp-interface {
    border-color: $chocolate;
  }

  .jp-controls li a,
  div.jp-volume-bar,
  div.jp-volume-bar-value {
    background-image: url(../images/jplayer.glassdimly.png) !important;
  }

}

.jp-audio {
  max-width: 100%;

}

.not-front .slogan-bio-wrapper {
  display: none;
  @include tiny-up {
    display: block;
  }
}

body.page-node-405 {
  .field-name-body p {
    font-size: 1.5rem;
  }

  #content {
    max-width: 900px;
  }
}

#block-block-18 {
  > p:first-of-type {
  margin-top: 0;
}
}

