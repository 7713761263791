/**
 * @file
 * Normalize-rtl.scss is the RTL language extension of normalize.scss
 */

/**
 * Lists
 */
dd {
  margin: 0 $indent-amount 0 0;
}

/* Address paddings set differently in IE 6/7. */
menu,
ol,
ul {
  padding: 0 $indent-amount 0 0;
}

@if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
  /**
   * Forms
   */
  legend {
    /* Correct alignment displayed oddly in IE 6/7. */
    *margin-left: 0;
    *margin-right: -7px;
  }
}
