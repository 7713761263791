/**
 * @file
 * Styles are organized using the SMACSS technique. @see http://smacss.com/book/
 *
 * When you turn on CSS aggregation at admin/config/development/performance, all
 * of these @include files will be combined into a single file.
 */
/* Import Sass mixins, variables, Compass modules, etc. */
//@import "../node_modules/compass-mixins/lib/compass";
//
///* Layout rules */
//@import "compass/layouts/fixed";
@import "init";
@import "mixins";
@import "glass_mixins";
@import "glass_variables";
///* HTML element (SMACSS base) rules */
@import "normalize";
/* Component (SMACSS module) rules */
//@import "components/misc";
// Optionally, add your own components here.
@import "print";
@import "components/misc.scss";
/* SMACSS theme rules */
/* @import "theme-A"; */
/* @import "theme-B"; */
@import "styles-rtl";
//glassdimly stylesheets
@import "glassdimly-zen";
@import "glass-typography";

// These media queries are mobile last. Change them.
@media all and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
  @import "glass-zen-tablet";
}

@media all and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
  @import "glass-zen-tablet-narrow";
  @import "glass-zen-tablet";

}

@media all and (max-width: $breakpoint-mobile) {
  @import "glass-zen-tablet-narrow";
  @import "glass-zen-tablet";
  @import "glass-zen-mobile";
}

@media all and (max-width: $breakpoint-desktop) {
  @import "../sass-folded/glass-folded";
}

@media only screen and (device-width: 768px) and (orientation: landscape) {

}