//generated with http://sebastianpontow.de/css2compass/. Get the colors by going there.
//colors
$color_red_approx: red;
$color_la_rioja_approx: #b1b100;
$black: #000000;
//var is already set
//$black: black;
$color_arapawa_approx: #000066;
$color_stiletto_approx: #993333;
$color_storm_dust_approx: #666666;
$color_forest_green_approx: #339933;
$gray: #808080;
//var is already set
//$gray: gray;
$color_ultramarine_approx: #000099;
$color_purple_approx: #660099;
$color_bahama_blue_approx: #006699;
$color_japanese_laurel_approx: #009900;
//var is already set
//$color_red_approx: #ff0000;
$color_dark_blue_approx: #0000dd;
$color_elm_approx: #208080;
$color_seance_approx: #800080;
$color_log_cabin_approx: #202020;
$color_cream_approx: #ffc;
$color_blue_approx: #0000ff;
$color_la_palma_approx: #339900;
//var is already set
//$color_japanese_laurel_approx: #008000;
$navy_blue: #000080;
$color_stratos_approx: #000040;
$teal: #008080;
//var is already set
//$teal: teal;
$color_blue_lagoon_approx: #007788;
$color_mantis_approx: #66cc66;
$color_orchid_approx: #cc66cc;
//var is already set
//$color_japanese_laurel_approx: #006600;
$color_smalt_approx: #003399;
$color_fun_green_approx: #006633;
$color_midnight_blue_approx: #003366;
$color_green_haze_approx: #009966;
$color_mariner_approx: #3366cc;
$color_guardsman_red_approx: #cc0000;
$color_pompadour_approx: #660066;
$color_red_berry_approx: #990000;
//var is already set
//$color_dark_blue_approx: #0000cc;
$color_salem_approx: #009933;
$color_dark_fern_approx: #004000;
$color_navy_blue_approx: #000088;
$color_flush_orange_approx: #ff7700;
$crimson: #dc143c;
//var is already set
//$crimson: crimson;
//var is already set
//$color_dark_blue_approx: #0000cd;
$color_gigas_approx: #483d8b;
$color_vermilion_approx: #ff4500;
$color_amethyst_approx: #9966cc;
$color_razzmatazz_approx: #cc0066;
$color_magenta_fuchsia_approx: #cc00ff;
$color_corn_harvest_approx: #996600;
$color_blue_stone_approx: #006666;
$color_electric_violet_approx: #9900cc;
$color_outrageous_orange_approx: #ff6633;
$color_blue_ribbon_approx: #0066ff;
$color_royal_blue_approx: #6666ff;
$color_red_orange_approx: #ff3333;

//fonts
$font_0: "Monaco","Courier New", monospace, sans-serif;

div.geshifilter {
  background:rgba(102, 57, 0, 0.1); //this is $chocolate
  border:1px solid #E5CD98; //this is the background color from eyedropper
  z-index: 3;
  position: relative;

  div {
    font-family: $font_0 !important; //important to override inline styles
    font-size:.9rem;
  }

  div.text{
    font-family: $font_0 !important;
    font-size:.8rem;
  }
}
html {
  code, kbd, pre, samp, tt, var {
    font-family: $font_0;
    line-height: 1.5;
    font-size:.9rem; //will this get applied? Dont know
  }
}
//@extend-elements
//original selectors
//.c.geshifilter-c .de1,  .c.geshifilter-c .de2
%extend_1 {
	font-family: $font_0;
	font-weight: normal;
	font-style: normal;
}

//original selectors
//.cpp.geshifilter-cpp .de1,  .cpp.geshifilter-cpp .de2
%extend_2 {
	font-family: $font_0;
	font-weight: normal;
	font-style: normal;
}

//original selectors
//.drupal5.geshifilter-drupal5 .de1,  .drupal5.geshifilter-drupal5 .de2
%extend_3 {
	font-family: $font_0;
	font-weight: normal;
	font-style: normal;
}

//original selectors
//.drupal6.geshifilter-drupal6 .de1,  .drupal6.geshifilter-drupal6 .de2
%extend_4 {
	font-family: $font_0;
	font-weight: normal;
	font-style: normal;
}

//original selectors
//.java.geshifilter-java .de1,  .java.geshifilter-java .de2
%extend_5 {
	font-family: $font_0;
	font-weight: normal;
	font-style: normal;
}

//original selectors
//.javascript.geshifilter-javascript .de1,  .javascript.geshifilter-javascript .de2
%extend_6 {
	font-family: $font_0;
	font-weight: normal;
	font-style: normal;
}

//original selectors
//.php.geshifilter-php .de1,  .php.geshifilter-php .de2
%extend_7 {
	font-family: $font_0;
	font-weight: normal;
	font-style: normal;
}

//original selectors
//.python.geshifilter-python .de1,  .python.geshifilter-python .de2
%extend_8 {
	font-family: $font_0;
	font-weight: normal;
	font-style: normal;
}

//original selectors
//.ruby.geshifilter-ruby .de1,  .ruby.geshifilter-ruby .de2
%extend_9 {
	font-family: $font_0;
	font-weight: normal;
	font-style: normal;
}


.c.geshifilter-c {
	font-family: $font_0;
	.de1 {
		@extend %extend_1;
	}
	.de2 {
		@extend %extend_1;
	}
	.imp {
		font-weight: bold;
		color: $color_red_approx;
	}
	li {
		font-family: $font_0;
		font-weight: normal;
		&.ln-xtra {
			background-color: $color_cream_approx;
		}
	}
	.li1 {
		font-family: $font_0;
		font-weight: normal;
	}
	.ln {
		width: 1px;
		text-align: right;
		margin: 0;
		padding: 0 2px;
		vertical-align: top;
	}
	.li2 {
		font-family: $font_0;
		font-weight: bold;
		font-style: italic;
	}
	.kw1 {
		color: $color_la_rioja_approx;
	}
	.kw2 {
		color: $black;
		font-weight: bold;
	}
	.kw3 {
		color: $color_arapawa_approx;
	}
	.kw4 {
		color: $color_stiletto_approx;
	}
	.co1 {
		color: $color_storm_dust_approx;
		font-style: italic;
	}
	.co2 {
		color: $color_forest_green_approx;
	}
	.coMULTI {
		color: $gray;
		font-style: italic;
	}
	.es0 {
		color: $color_ultramarine_approx;
		font-weight: bold;
	}
	.es1 {
		color: $color_ultramarine_approx;
		font-weight: bold;
	}
	.es2 {
		color: $color_purple_approx;
		font-weight: bold;
	}
	.es3 {
		color: $color_purple_approx;
		font-weight: bold;
	}
	.es4 {
		color: $color_purple_approx;
		font-weight: bold;
	}
	.es5 {
		color: $color_bahama_blue_approx;
		font-weight: bold;
	}
	.br0 {
		color: $color_japanese_laurel_approx;
	}
	.sy0 {
		color: $color_forest_green_approx;
	}
	.st0 {
		color: $color_red_approx;
	}
	.nu0 {
		color: $color_dark_blue_approx;
	}
	.nu6 {
		color: $color_elm_approx;
	}
	.nu8 {
		color: $color_elm_approx;
	}
	.nu12 {
		color: $color_elm_approx;
	}
	.nu16 {
		color: $color_seance_approx;
	}
	.nu17 {
		color: $color_seance_approx;
	}
	.nu18 {
		color: $color_seance_approx;
	}
	.nu19 {
		color: $color_seance_approx;
	}
	.me1 {
		color: $color_log_cabin_approx;
	}
	.me2 {
		color: $color_log_cabin_approx;
	}
	.ln-xtra {
		background-color: $color_cream_approx;
	}
	div.ln-xtra {
		background-color: $color_cream_approx;
	}
	span.xtra {
		display: block;
	}
}
.cpp.geshifilter-cpp {
	font-family: $font_0;
	.de1 {
		@extend %extend_2;
	}
	.de2 {
		@extend %extend_2;
	}
	.imp {
		font-weight: bold;
		color: $color_red_approx;
	}
	li {
		font-family: $font_0;
		font-weight: normal;
		&.ln-xtra {
			background-color: $color_cream_approx;
		}
	}
	.li1 {
		font-family: $font_0;
		font-weight: normal;
	}
	.ln {
		width: 1px;
		text-align: right;
		margin: 0;
		padding: 0 2px;
		vertical-align: top;
	}
	.li2 {
		font-family: $font_0;
		font-weight: bold;
		font-style: italic;
	}
	.kw1 {
		color: $color_blue_approx;
	}
	.kw2 {
		color: $color_blue_approx;
	}
	.kw3 {
		color: $color_dark_blue_approx;
	}
	.kw4 {
		color: $color_blue_approx;
	}
	.co1 {
		color: $color_storm_dust_approx;
	}
	.co2 {
		color: $color_la_palma_approx;
	}
	.coMULTI {
		color: $color_red_approx;
		font-style: italic;
	}
	.es0 {
		color: $color_ultramarine_approx;
		font-weight: bold;
	}
	.es1 {
		color: $color_ultramarine_approx;
		font-weight: bold;
	}
	.es2 {
		color: $color_purple_approx;
		font-weight: bold;
	}
	.es3 {
		color: $color_purple_approx;
		font-weight: bold;
	}
	.es4 {
		color: $color_purple_approx;
		font-weight: bold;
	}
	.es5 {
		color: $color_bahama_blue_approx;
		font-weight: bold;
	}
	.br0 {
		color: $color_japanese_laurel_approx;
	}
	.sy0 {
		color: $color_japanese_laurel_approx;
	}
	.sy1 {
		color: $navy_blue;
	}
	.sy2 {
		color: $color_stratos_approx;
	}
	.sy3 {
		color: $color_stratos_approx;
	}
	.sy4 {
		color: $teal;
	}
	.st0 {
		color: #FF0000;
	}
	.nu0 {
		color: $color_dark_blue_approx;
	}
	.nu6 {
		color: $color_elm_approx;
	}
	.nu8 {
		color: $color_elm_approx;
	}
	.nu12 {
		color: $color_elm_approx;
	}
	.nu16 {
		color: $color_seance_approx;
	}
	.nu17 {
		color: $color_seance_approx;
	}
	.nu18 {
		color: $color_seance_approx;
	}
	.nu19 {
		color: $color_seance_approx;
	}
	.me1 {
		color: $color_blue_lagoon_approx;
	}
	.me2 {
		color: $color_blue_lagoon_approx;
	}
	.ln-xtra {
		background-color: $color_cream_approx;
	}
	div.ln-xtra {
		background-color: $color_cream_approx;
	}
	span.xtra {
		display: block;
	}
}
.drupal5.geshifilter-drupal5 {
	font-family: $font_0;
	.de1 {
		@extend %extend_3;
	}
	.de2 {
		@extend %extend_3;
	}
	.imp {
		font-weight: bold;
		color: $color_red_approx;
	}
	li {
		font-family: $font_0;
		font-weight: normal;
		&.ln-xtra {
			background-color: $color_cream_approx;
		}
	}
	.li1 {
		font-family: $font_0;
		font-weight: normal;
	}
	.ln {
		width: 1px;
		text-align: right;
		margin: 0;
		padding: 0 2px;
		vertical-align: top;
	}
	.li2 {
		font-family: $font_0;
		font-weight: bold;
		font-style: italic;
	}
	.kw1 {
		color: $color_la_rioja_approx;
	}
	.kw2 {
		color: $black;
		font-weight: bold;
	}
	.kw3 {
		color: $color_arapawa_approx;
	}
	.kw5 {
		color: $color_arapawa_approx;
	}
	.kw6 {
		color: $black;
		font-weight: bold;
	}
	.co1 {
		color: $gray;
		font-style: italic;
	}
	.co2 {
		color: $gray;
		font-style: italic;
	}
	.coMULTI {
		color: $gray;
		font-style: italic;
	}
	.es0 {
		color: $color_ultramarine_approx;
		font-weight: bold;
	}
	.br0 {
		color: $color_mantis_approx;
	}
	.sy0 {
		color: $color_mantis_approx;
	}
	.st0 {
		color: $color_red_approx;
	}
	.nu0 {
		color: $color_orchid_approx;
	}
	.me1 {
		color: $color_japanese_laurel_approx;
	}
	.me2 {
		color: $color_japanese_laurel_approx;
	}
	.re0 {
		color: $color_blue_approx;
	}
	.re1 {
		color: $color_red_approx;
	}
	.ln-xtra {
		background-color: $color_cream_approx;
	}
	div.ln-xtra {
		background-color: $color_cream_approx;
	}
	span.xtra {
		display: block;
	}
}
.drupal6.geshifilter-drupal6 {
	font-family: $font_0;
	.de1 {
		@extend %extend_4;
	}
	.de2 {
		@extend %extend_4;
	}
	.imp {
		font-weight: bold;
		color: $color_red_approx;
	}
	li {
		font-family: $font_0;
		font-weight: normal;
		&.ln-xtra {
			background-color: $color_cream_approx;
		}
	}
	.li1 {
		font-family: $font_0;
		font-weight: normal;
	}
	.ln {
		width: 1px;
		text-align: right;
		margin: 0;
		padding: 0 2px;
		vertical-align: top;
	}
	.li2 {
		font-family: $font_0;
		font-weight: bold;
		font-style: italic;
	}
	.kw1 {
		color: $color_la_rioja_approx;
	}
	.kw2 {
		color: $black;
		font-weight: bold;
	}
	.kw3 {
		color: $color_arapawa_approx;
	}
	.kw5 {
		color: $color_arapawa_approx;
	}
	.kw6 {
		color: $black;
		font-weight: bold;
	}
	.co1 {
		color: $gray;
		font-style: italic;
	}
	.co2 {
		color: $gray;
		font-style: italic;
	}
	.coMULTI {
		color: $gray;
		font-style: italic;
	}
	.es0 {
		color: $color_ultramarine_approx;
		font-weight: bold;
	}
	.br0 {
		color: $color_mantis_approx;
	}
	.sy0 {
		color: $color_mantis_approx;
	}
	.st0 {
		color: $color_red_approx;
	}
	.nu0 {
		color: $color_orchid_approx;
	}
	.me1 {
		color: $color_japanese_laurel_approx;
	}
	.me2 {
		color: $color_japanese_laurel_approx;
	}
	.re0 {
		color: $color_blue_approx;
	}
	.re1 {
		color: $color_red_approx;
	}
	.ln-xtra {
		background-color: $color_cream_approx;
	}
	div.ln-xtra {
		background-color: $color_cream_approx;
	}
	span.xtra {
		display: block;
	}
}
.java.geshifilter-java {
	font-family: $font_0;
	.de1 {
		@extend %extend_5;
	}
	.de2 {
		@extend %extend_5;
	}
	.imp {
		font-weight: bold;
		color: $color_red_approx;
	}
	li {
		font-family: $font_0;
		font-weight: normal;
		&.ln-xtra {
			background-color: $color_cream_approx;
		}
	}
	.li1 {
		font-family: $font_0;
		font-weight: normal;
	}
	.ln {
		width: 1px;
		text-align: right;
		margin: 0;
		padding: 0 2px;
		vertical-align: top;
	}
	.li2 {
		font-family: $font_0;
		font-weight: bold;
		font-style: italic;
	}
	.kw1 {
		color: $black;
		font-weight: bold;
	}
	.kw2 {
		color: $color_arapawa_approx;
		font-weight: bold;
	}
	.kw3 {
		color: $color_smalt_approx;
	}
	.kw4 {
		color: $color_arapawa_approx;
		font-weight: bold;
	}
	.co1 {
		color: $color_storm_dust_approx;
		font-style: italic;
	}
	.co2 {
		color: $color_bahama_blue_approx;
	}
	.co3 {
		color: $color_japanese_laurel_approx;
		font-style: italic;
		font-weight: bold;
	}
	.coMULTI {
		color: $color_storm_dust_approx;
		font-style: italic;
	}
	.es0 {
		color: $color_ultramarine_approx;
		font-weight: bold;
	}
	.br0 {
		color: $color_japanese_laurel_approx;
	}
	.sy0 {
		color: $color_forest_green_approx;
	}
	.st0 {
		color: $color_blue_approx;
	}
	.nu0 {
		color: $color_orchid_approx;
	}
	.me1 {
		color: $color_fun_green_approx;
	}
	.me2 {
		color: $color_fun_green_approx;
	}
	.ln-xtra {
		background-color: $color_cream_approx;
	}
	div.ln-xtra {
		background-color: $color_cream_approx;
	}
	span.xtra {
		display: block;
	}
}
.javascript.geshifilter-javascript {
	font-family: $font_0;
	.de1 {
		@extend %extend_6;
	}
	.de2 {
		@extend %extend_6;
	}
	.imp {
		font-weight: bold;
		color: $color_red_approx;
	}
	li {
		font-family: $font_0;
		font-weight: normal;
		&.ln-xtra {
			background-color: $color_cream_approx;
		}
	}
	.li1 {
		font-family: $font_0;
		font-weight: normal;
	}
	.ln {
		width: 1px;
		text-align: right;
		margin: 0;
		padding: 0 2px;
		vertical-align: top;
	}
	.li2 {
		font-family: $font_0;
		font-weight: bold;
		font-style: italic;
	}
	.kw1 {
		color: $color_arapawa_approx;
		font-weight: bold;
	}
	.kw2 {
		color: $color_midnight_blue_approx;
		font-weight: bold;
	}
	.kw3 {
		color: $color_arapawa_approx;
	}
	.kw5 {
		color: #FF0000;
	}
	.co1 {
		color: $color_japanese_laurel_approx;
		font-style: italic;
	}
	.co2 {
		color: $color_green_haze_approx;
		font-style: italic;
	}
	.coMULTI {
		color: $color_japanese_laurel_approx;
		font-style: italic;
	}
	.es0 {
		color: $color_ultramarine_approx;
		font-weight: bold;
	}
	.br0 {
		color: $color_japanese_laurel_approx;
	}
	.sy0 {
		color: $color_forest_green_approx;
	}
	.st0 {
		color: #3366CC;
	}
	.nu0 {
		color: #CC0000;
	}
	.me1 {
		color: $color_pompadour_approx;
	}
	.ln-xtra {
		background-color: $color_cream_approx;
	}
	div.ln-xtra {
		background-color: $color_cream_approx;
	}
	span.xtra {
		display: block;
	}
}
.php.geshifilter-php {
	font-family: $font_0;
	.de1 {
		@extend %extend_7;
	}
	.de2 {
		@extend %extend_7;
	}
	.imp {
		font-weight: bold;
		color: $color_red_approx;
	}
	li {
		font-family: $font_0;
		font-weight: normal;
		&.ln-xtra {
			background-color: $color_cream_approx;
		}
	}
	.li1 {
		font-family: $font_0;
		font-weight: normal;
	}
	.ln {
		width: 1px;
		text-align: right;
		margin: 0;
		padding: 0 2px;
		vertical-align: top;
	}
	.li2 {
		font-family: $font_0;
		font-weight: bold;
		font-style: italic;
	}
	.kw1 {
		color: $color_la_rioja_approx;
	}
	.kw2 {
		color: $black;
		font-weight: bold;
	}
	.kw3 {
		color: $color_red_berry_approx;
	}
	.kw4 {
		color: $color_japanese_laurel_approx;
		font-weight: bold;
	}
	.co1 {
		color: $color_storm_dust_approx;
		font-style: italic;
	}
	.co2 {
		color: $color_storm_dust_approx;
		font-style: italic;
	}
	.co3 {
		color: $color_dark_blue_approx;
		font-style: italic;
	}
	.co4 {
		color: $color_salem_approx;
		font-style: italic;
	}
	.coMULTI {
		color: $color_storm_dust_approx;
		font-style: italic;
	}
	.es0 {
		color: $color_ultramarine_approx;
		font-weight: bold;
	}
	.es1 {
		color: $color_ultramarine_approx;
		font-weight: bold;
	}
	.es2 {
		color: $color_purple_approx;
		font-weight: bold;
	}
	.es3 {
		color: $color_purple_approx;
		font-weight: bold;
	}
	.es4 {
		color: $color_bahama_blue_approx;
		font-weight: bold;
	}
	.es5 {
		color: $color_bahama_blue_approx;
		font-weight: bold;
		font-style: italic;
	}
	.es6 {
		color: $color_salem_approx;
		font-weight: bold;
	}
	.es_h {
		color: $color_ultramarine_approx;
		font-weight: bold;
	}
	.br0 {
		color: $color_japanese_laurel_approx;
	}
	.sy0 {
		color: $color_forest_green_approx;
	}
	.sy1 {
		color: $black;
		font-weight: bold;
	}
	.st0 {
		color: $color_blue_approx;
	}
	.st_h {
		color: $color_blue_approx;
	}
	.nu0 {
		color: $color_orchid_approx;
	}
	.nu8 {
		color: $color_elm_approx;
	}
	.nu12 {
		color: $color_elm_approx;
	}
	.nu19 {
		color: $color_seance_approx;
	}
	.me1 {
		color: $color_dark_fern_approx;
	}
	.me2 {
		color: $color_dark_fern_approx;
	}
	.re0 {
		color: $color_navy_blue_approx;
	}
	.ln-xtra {
		background-color: $color_cream_approx;
	}
	div.ln-xtra {
		background-color: $color_cream_approx;
	}
	span.xtra {
		display: block;
	}
}
.python.geshifilter-python {
	font-family: $font_0;
	.de1 {
		@extend %extend_8;
	}
	.de2 {
		@extend %extend_8;
	}
	.imp {
		font-weight: bold;
		color: $color_red_approx;
	}
	li {
		font-family: $font_0;
		font-weight: normal;
		&.ln-xtra {
			background-color: $color_cream_approx;
		}
	}
	.li1 {
		font-family: $font_0;
		font-weight: normal;
	}
	.ln {
		width: 1px;
		text-align: right;
		margin: 0;
		padding: 0 2px;
		vertical-align: top;
	}
	.li2 {
		font-family: $font_0;
		font-weight: bold;
		font-style: italic;
	}
	.kw1 {
		color: $color_flush_orange_approx;
		font-weight: bold;
	}
	.kw2 {
		color: $color_japanese_laurel_approx;
	}
	.kw3 {
		color: $crimson;
	}
	.kw4 {
		color: $color_dark_blue_approx;
	}
	.co1 {
		color: $gray;
		font-style: italic;
	}
	.coMULTI {
		color: $gray;
		font-style: italic;
	}
	.es0 {
		color: $color_ultramarine_approx;
		font-weight: bold;
	}
	.br0 {
		color: $black;
	}
	.sy0 {
		color: $color_mantis_approx;
	}
	.st0 {
		color: $color_gigas_approx;
	}
	.nu0 {
		color: $color_vermilion_approx;
	}
	.me1 {
		color: $black;
	}
	.ln-xtra {
		background-color: $color_cream_approx;
	}
	div.ln-xtra {
		background-color: $color_cream_approx;
	}
	span.xtra {
		display: block;
	}
}
.ruby.geshifilter-ruby {
	font-family: $font_0;
	.de1 {
		@extend %extend_9;
	}
	.de2 {
		@extend %extend_9;
	}
	.imp {
		font-weight: bold;
		color: $color_red_approx;
	}
	li {
		font-family: $font_0;
		font-weight: normal;
		&.ln-xtra {
			background-color: $color_cream_approx;
		}
	}
	.li1 {
		font-family: $font_0;
		font-weight: normal;
	}
	.ln {
		width: 1px;
		text-align: right;
		margin: 0;
		padding: 0 2px;
		vertical-align: top;
	}
	.li2 {
		font-family: $font_0;
		font-weight: bold;
		font-style: italic;
	}
	.kw1 {
		color: #9966CC;
		font-weight: bold;
	}
	.kw2 {
		color: #0000FF;
		font-weight: bold;
	}
	.kw3 {
		color: #CC0066;
		font-weight: bold;
	}
	.kw4 {
		color: #CC00FF;
		font-weight: bold;
	}
	.co1 {
		color: $color_japanese_laurel_approx;
		font-style: italic;
	}
	.co4 {
		color: $color_guardsman_red_approx;
		font-style: italic;
	}
	.coMULTI {
		color: $navy_blue;
		font-style: italic;
	}
	.es0 {
		color: $color_ultramarine_approx;
	}
	.br0 {
		color: $color_japanese_laurel_approx;
		font-weight: bold;
	}
	.sy0 {
		color: $color_japanese_laurel_approx;
		font-weight: bold;
	}
	.st0 {
		color: $color_corn_harvest_approx;
	}
	.nu0 {
		color: $color_blue_stone_approx;
	}
	.me1 {
		color: #9900CC;
	}
	.re0 {
		color: $color_outrageous_orange_approx;
		font-weight: bold;
	}
	.re1 {
		color: $color_blue_ribbon_approx;
		font-weight: bold;
	}
	.re2 {
		color: $color_royal_blue_approx;
		font-weight: bold;
	}
	.re3 {
		color: $color_red_orange_approx;
		font-weight: bold;
	}
	.ln-xtra {
		background-color: $color_cream_approx;
	}
	div.ln-xtra {
		background-color: $color_cream_approx;
	}
	span.xtra {
		display: block;
	}
}