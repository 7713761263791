@mixin chrome-font-normalize {
  -webkit-text-stroke: 0.25px;
  font-weight: normal;
}

// See _glass_mixins for vars.
//$breakpoint-desktop:900px;
//$breakpoint-tablet:770px;
//$breakpoint-mobile:490px;
$breakpoint-desktop-down: $breakpoint-desktop -1;
$breakpoint-tablet-down: $breakpoint-tablet - 1;
$breakpoint-mobile-down: $breakpoint-mobile -1;

@mixin desktop-up {
  @media only screen and (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin xlarge-desktop-down {
  @media only screen and (max-width: 1300px) {
    @content;
  }
}

@mixin xlarge-desktop-up {
  @media only screen and (min-width: 1299px) {
    @content;
  }
}

@mixin desktop-down {
  @media only screen and (max-width: $breakpoint-desktop-down) {
    @content;
  }
}

@mixin tablet-up {
  @media only screen and (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin tablet-down {
  @media only screen and (max-width: $breakpoint-tablet-down) {
    @content;
  }
}

@mixin mobile-up {
  @media only screen and (min-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin mobile-down {
  @media only screen and (max-width: $breakpoint-mobile-down) {
    @content;
  }
}

@mixin tiny-up {
  @media only screen and (min-width: 350px) {
    @content;
  }
}