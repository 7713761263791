#page {
  aside.sidebars {
    display: none;
  }

  #block-glass-block-reader-apply-mobile {
    display:block;
  }

  #comments{
    h2.comment-form {
      background-position: 55% 60%;
    }
  }

  #header {
    #toc-header {
      span.fieldset-legend {
        border:none !important;
      }
      hr.border {
        display:none !important;
      }
      #links__system_main_menu li{
        font-size:1.7rem;
      }
    }
  }
}
body.toc-header-active-fallback #toc-header ul.parent .fieldset-legend,
body.toc-header-active  #toc-header ul.parent .fieldset-legend {
  background-color:transparent;
}
body.toc-header-active-fallback #page #toc-header ul.parent:hover,
body.toc-header-active #page #toc-header ul.parent  {
  background-color: $creme !important;
  border-top:1px solid $chocolate !important;
  border-left:1px solid $chocolate !important;
  border-right:1px solid $chocolate !important;
}

body, html, div, span, header, article, p, form, aside, nav, h1, h2, h3, h4, h5, h6 {
  //max-width:590px !important;
}

