body.html {
  footer {
    //background: no-repeat center/100% url('/sites/all/themes/glassdimly_zen/images/bottom-folded-full.png');
    //width: 100.35%;
    width: 100%;
    //padding-top: 11.7%;
    margin-left: 0;
  }

  #main {
    //background: repeat-y center/100% url("/sites/all/themes/glassdimly_zen/images/middle-folded-full.png");
    //width: 99.6%;
    width: 100%;
  }

  #block-glass-block-toc-mobile {
    display:block;
  }

  #header {
    //background: no-repeat center/cover url('/sites/all/themes/glassdimly_zen/images/top-folded-full.png');

    #name-and-slogan {
      margin-top: 3%;
      width: $glass-folded-page-width-no-gutter;
      position: absolute;
      top: 40%;

      #header-left {
        width: 100%;
      }

      #header-right {
        width: 100%;
        display: block;
      }
    }
  }

  #content {
    width: $glass-folded-width-content;
    padding-left: $glass-folded-padding-left;
    padding-right: $glass-folded-padding-right;
    margin-top: 2%;
    margin-right: 0px;
    margin-left: 0px;
    min-height: 300px;
  }

  #header-image {
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }
  }
}

body.front {

  #content {
    @include tablet-up {
    }
  }
}

aside.sidebars {
  display: none;
}

#user-login #edit-actions {
  height: 100px;
}