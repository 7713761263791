$breakpoint-desktop: 900px;
$breakpoint-tablet: 770px;
$breakpoint-mobile: 490px;

$zen-grid-width: 1000px;
$max-content-width: 1200px;

$front-left-total-width: 65%;
$front-left-padding-left: 6%;
$front-left-padding-right: 3%;

$front-right-padding-left: 3%;
$front-right-padding-right: 6%;
$front-right-total-width: 100% - $front-left-total-width;

$glass-spine-width: $front-right-padding-left + $front-left-padding-right;
$glass-both-pages-width: 100% - (2 * $glass-spine-width);
$glass-left-page-width: $front-left-total-width - $glass-spine-width;
$glass-right-page-width: $front-right-total-width  - $glass-spine-width;
$glass-folded-width-full:8%; // this is incorrect

$glass-page-gutter: $front-right-padding-left + $front-left-padding-right;
$glass-content-width: $glass-left-page-width - ($glass-page-gutter * 2);

$glass-folded-page-edges-left: 2%;
$glass-folded-curled-page-width: 2%;
$glass-folded-page-width-no-gutter: 100% - $glass-folded-page-edges-left - $glass-folded-curled-page-width;
$glass-folded-padding-left: 6%;
$glass-folded-padding-right: 6%;
$glass-folded-width-content: 100% - $glass-folded-padding-left - $glass-folded-padding-right;

$font-stack-body:"Crimson Text", "Hoefler Text", Georgia, "Times New Roman", Times, "DejaVu Serif", serif;
$font-stack-sc: "Alegreya SC", 'IMFellDoublePicaFF',"Courier New", Courier, monospace;
$font-stack-header: "Alegreya", 'IM Fell DW Pica',"Courier New", Courier, monospace;
$font-stack-dropcap: 'Eileen','IMFellDoublePicaFF',"Courier New",Courier,monospace;
$base-font-family: $font-stack-body;

$black-brown: #2A1900;
$dark-brown: #3F2500;
$chocolate: hsl(25, 100%, 25%);// hsl(0, 100%, 20%);// hsl(34, 100%, 20%);
$tan: #BEAB8A;
$creme: hsl(65, 60%, 98%);
$dark-creme: hsl(42, 60%, 85%);
$light-blue-gray: #D8DFEA;
$logo-color: #572D2C;
$background: hsl(36, 100%, 95%);
