/**
 * @file
 * RTL companion for the modular-styles.css file.
 */

/**
 * Branding header.
 */

/* Wrapping link for logo. */
.header__logo {
  float: right;
}

/* The secondary menu (login, etc.) */
.header__secondary-menu {
  float: left;
}

/**
 * Navigation bar.
 */

/* Main menu and secondary menu links and menu block links. */
#navigation {
  .links,
  .menu {
    text-align: right;

    li {
      /* A simple method to get navigation links to appear in one line. */
      float: right;
      padding: 0 0 0 10px;
    }
  }
}

/**
 * Messages.
 */
.messages {
  padding: 10px 50px 10px 10px;
  background-position: 99% 8px;
}
.messages--status {
  @extend .messages;
}
.messages--warning {
  @extend .messages;
}
.messages--error {
  @extend .messages;
}

/**
 * Tabs.
 */
%tabs__tab {
  float: right;
}
.tabs-primary__tab {
  @extend %tabs__tab;
}
.tabs-primary__tab.is-active {
  @extend .tabs-primary__tab;
}
.tabs-secondary__tab,
.tabs-secondary__tab.is-active {
  @extend %tabs__tab;
}

/**
 * Inline styles.
 */

/* List of links */
.inline li {
  /* Bug in Safari causes display: inline to fail. */
  display: inline-block;
  padding: 0 0 0 1em;
}

/* The inline field label used by the Fences.module */
span.field-label {
  padding: 0 0 0 1em;
}

/**
 * "More" links.
 */
.more-link {
  text-align: left;
}
.more-help-link {
  text-align: left;
}
.more-help-link a {
  background-position: 100% 50%;
  padding: 1px 20px 1px 0;
}

/**
 * Menus.
 */
.menu__item.is-collapsed {
  list-style-image: inline-image("menu-collapsed-rtl.png");
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    *list-style-image: image-url("menu-collapsed-rtl.png");
  }
}

/**
 * Comments.
 */

/* Nested comments are indented. */
.indented {
  margin-left: 0;
  margin-right: $indent-amount;
}

/**
 * Forms.
 */

/* Drupal's default login form block */
#user-login-form {
  text-align: right;
}

html.js #user-login-form li.openid-link,
#user-login-form li.openid-link {
  /* Un-do some of the padding on the ul list. */
  margin-left: 0;
  margin-right: -20px;
}

/*
 * Drupal admin tables.
 */
form {
  th {
    text-align: right;
    padding-left: 1em;
    padding-right: 0;
  }
}

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */
//.fieldset-legend {
//  html.js .collapsible & {
//    background-position: 98% 75%;
//    padding-left: 0;
//    padding-right: 15px;
//  }
//  html.js .collapsed & {
//    background-image: inline-image("menu-collapsed-rtl.png");
//    @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
//      *background-image: image-url("menu-collapsed-rtl.png");
//    }
//    background-position: 98% 50%;
//  }
//}
